import React, { FC, ReactElement, SyntheticEvent }  from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './LanguageSelector.css';

interface OwnProps {
    languageDetected: string;
}

const languageOptions = [
  { key: 'en', text: 'EN', value: 'en' },
  { key: 'fr', text: 'FR', value: 'fr' },
  { key: 'de', text: 'DE', value: 'de' },
  { key: 'it', text: 'IT', value: 'it' },
]

type Props =  OwnProps & RouteComponentProps;

const LanguageSelector: FC<Props> = (props: Props): ReactElement => {
    const { history } = props;

    const onChange = (event: SyntheticEvent, data: DropdownProps) => {
        event.preventDefault();
        if ( data.value !== null && data.value )
        {
            console.log(data.value);
            if ( data.value.toString().toUpperCase() !== props.languageDetected )
            {
                history.push('/?lng=' + data.value);
                history.go(0)
            }
        }
    }

    return (
          <Dropdown
            button
            className='icon'
            floating
            labeled
            icon='world'
            options={languageOptions}
            search
            text={props.languageDetected}
            defaultValue={props.languageDetected.toLowerCase()}
            onChange={onChange}
          />
    );
}

export default withRouter(LanguageSelector);